import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Title from '../components/layout/title';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const IssuesPage = ({ location, data }) => {
  const Housing = getImage(data.Housing.childImageSharp);
  const Transportation = getImage(data.Transportation.childImageSharp);
  const PublicSafety = getImage(data.PublicSafety.childImageSharp);
  const PublicSchools = getImage(data.PublicSchools.childImageSharp);
  const ReduceHomelessness = getImage(data.ReduceHomelessness.childImageSharp);
  const SmallBusiness = getImage(data.SmallBusiness.childImageSharp);
  const TechInnovation = getImage(data.TechInnovation.childImageSharp);

  const Issue = ({ headline, description, image, bullets, children }) => (
    <div className="grid md:grid-cols-12 sm:gap-12 lg:gap-24 py-8 sm:py-16">
      <div className="col-span-12 sm:col-span-6 lg:col-span-7 order-last sm:order-first">
        <h2 className="text-3xl sm:text-4xl mb-6 font-bold sm:font-black leading-2 tracking-tight text-left">
          {headline}
        </h2>
        <p className="mt-3 text-xl text-gray-700 leading-relaxed">{description}</p>
        <h2 className="text-lg mt-8 mb-4 font-bold leading-5 tracking-tight text-left">
          We Support
        </h2>
        <ul className="sm:columns-2 md:gap-12 list-disc pl-6 text-gray-700">
          {bullets &&
            bullets.map((bullet, index) => (
              <li key={index} className="break-inside-avoid-column">
                {bullet}
              </li>
            ))}
        </ul>
      </div>
      <div className="col-span-12 sm:col-span-6 lg:col-span-5 mb-8 sm:mb-0 w-full">
        <div className="">
          <GatsbyImage
            className="max-h-60 sm:max-h-screen w-full z-0 upper-left-clip"
            image={image}
            alt={headline}
            objectPosition="0 50%"
          />
        </div>
      </div>
    </div>
  );

  const issuesContent = [
    {
      title: 'More Housing',
      description:
        'San Francisco has a housing shortage. Restricting supply only drives prices higher while driving young families away. Our future depends on making room for the next generation, which is why we must create multi-family housing in every neighborhood — including the western and southwestern sides of the city that only allow single-family homes.',
      image: Housing,
      bullets: [
        'Make it easier to build housing at all price points. More housing allows young people to achieve homeownership and seniors to downsize from their homes and safely age in place in an elevator building in their neighborhood. A vibrant housing market with adequate supply helps both tenants and homeowners.',
        'Legalize multi-family housing in every neighborhood in San Francisco.',
      ],
    },
    {
      title: 'Streets & Transportation',
      description: `We support a fully-funded Muni, but we also expect accountability and efficiency from our public transportation system. We will ensure Muni is run by competent professionals and not derailed by a few gadflies who only want more parking. Meanwhile, electric and pedal assist bikes will make biking a fast, pleasant and viable option for all ages — if we also make room for bikes to get around safely and free from car traffic.`,
      image: Transportation,
      bullets: [
        'Build a city-wide connected network of protected bike lanes',
        'Create a new oceanside park at the former Great Highway and the car-free JFK Promenade at JFK Drive in Golden Gate Park.',
        "Plan and build the subway tunnels we regret not building decades ago, including West Portal to Parkmerced under 19th Avenue, Geary Boulevard from downtown to the ocean, and expanding the Central Subway from Chinatown to Fisherman's Wharf. We also need a new BART tunnel across San Francisco Bay.",
      ],
    },
    {
      title: 'Public Safety',
      description: `San Francisco's property crime rate is among the highest in California and in both 2020 and 2021, more San Franciscans died from fentanyl overdose than from COVID-19.`,
      image: PublicSafety,
      bullets: [
        'Adequately fund the police department to ensure we have enough officers and detectives to solve violent crime and arrest organized theft rings and the drug dealers dispensing death on our sidewalks.',
        'Hire diverse, well-trained officers who will serve at the highest standard, with no tolerance for corruption or abuse.',
        'Elect a district attorney who will effectively prosecute crimes by dangerous and repeat offenders. Our district attorney should be reform-minded while keeping everyone safe. We believe safety and justice go together.',
      ],
    },
    {
      title: 'Public Schools',
      description: `We will ensure our public school system becomes one of the best in the nation: well-funded, free from political interference, and with programs for students at every level. Our public schools must be competitive with private ones.`,
      image: PublicSchools,
      bullets: [
        'Ensure we elect a rational and competent school board that puts the needs of students and families above political ideology.',
        'Treat parents like partners and offer what they want, which includes more public magnet schools in language, arts and sciences.',
        'Provide advanced classes for high-performing students. Otherwise parents will continue to choose private schools as public school enrollment declines.',
        'Change the school assignment system to allow families to walk their children to school. Walkable schools strengthen communities, reduce carbon emission when thousands of kids aren’t driven across town twice daily, and allow for more family time at home.',
      ],
    },
    {
      title: 'Reduce Homelessness',
      description: `San Francisco has one of the highest rates of homelessness in the country (including a shocking number of homeless school kids). We must take a compassionate approach to addressing homelessness, because no one should be forced to live on the sidewalk.`,
      image: ReduceHomelessness,
      bullets: [
        'Build more housing of all types — including "tiny homes" — so that we stop pushing people into homelessness from extraordinary housing costs.',
        'Build more shelters for people experiencing homelessness, including temporary shelters and navigation centers, so that everyone has a safe place to sleep.',
        'Address the mental health crisis that is an underlying factor for too many people by expanding conservatorship laws and opening a new generation of mental health facilities where people with severe mental illness can get the treatment they need.',
      ],
    },
    {
      title: 'Local Economy and Small Businesses',
      description: `San Francisco is one of the worst cities to do business in North America. Businesses were closing in San Francisco long before COVID hit. City Hall’s excessive fees and regulations are to blame. Starting a business in San Francisco can range from maddening to impossible because City Hall bureaucracy is designed to stifle good ideas. Yet there are ideas we haven’t even thought about yet that could revolutionize our merchant corridors in a post-pandemic world.`,
      image: SmallBusiness,
      bullets: [
        'Streamline approvals and waive fees to reward entrepreneurs willing to try new ideas and take the risk of starting a small business.',
        "Celebrate innovation — don't stifle it. City Hall needs to get out of the way and let every idea have a chance to be the one that saves our local economy.",
      ],
    },
    /*     {
      title: 'Tech & Innovation',
      description: `We believe the tech community is an important contributor to San Francisco’s dynamic economy and culture. Tech workers are creators and artists. We recognize that they are our colleagues, friends, neighbors and family members. They contribute to San Francisco's vibrancy and diversity, continuing a long history of newcomers transforming the city for the better.`,
      image: TechInnovation,
      bullets: [
        'Encourage and facilitate tech workers to engage in their communities and the political discourse.',
        'Encourage City Hall to embrace the benefits of tech and innovation.',
      ],
    }, */
  ];

  return (
    <Layout>
      <SEO
        title="GrowSF: The issues we care about"
        description="These are the issues we care about and will fight for. We support policies that make sense for San Francisco's future, not its past."
        pathname={location.pathname}
        image={data?.opengraph?.childImageSharp.resize || null}
      />
      <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8">
        <Title title="Our Issues">
          These are the issues we care about and will fight for. We support policies that make sense
          for San Francisco's future, not its past. If this is the San Francisco you want to see,{' '}
          <Link to="/join/" className="underline font-bold text-brand-blue-4">
            sign up
          </Link>{' '}
          and we'll keep you informed about what's going on and how to get involved.
        </Title>

        <div className="">
          {issuesContent.map((issue, index) => (
            <Issue
              key={index}
              headline={issue.title}
              description={issue.description}
              image={issue.image}
              bullets={issue.bullets}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default IssuesPage;

export const query = graphql`
  query {
    DoloresParkVertical: file(relativePath: { eq: "core/dolores-vertical.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    Housing: file(relativePath: { eq: "issues/bernadette-gatsby-bN_TkedaBuQ-unsplash.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    Transportation: file(relativePath: { eq: "issues/varla-scooter-k0BsRMhrkhQ-unsplash.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    PublicSafety: file(relativePath: { eq: "issues/mattia-bericchia-4MiMiKJoA78-unsplash.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    PublicSchools: file(relativePath: { eq: "issues/jeswin-thomas-FIxxQDwpJ2g-unsplash.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    ReduceHomelessness: file(relativePath: { eq: "issues/nina-strehl-Ds0ZIA5gzc4-unsplash.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    SmallBusiness: file(relativePath: { eq: "issues/dan-gold-ZnPNZpjzi0M-unsplash.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    TechInnovation: file(
      relativePath: { eq: "issues/priscilla-du-preez-XkKCui44iM0-unsplash.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    opengraph: file(relativePath: { eq: "listing/issues_opengraph.png" }) {
      id
      childImageSharp {
        gatsbyImageData
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;
